@import '../../styles/theme.scss';
@import '../../styles/media-breakpoints.scss';

.signIn{
  max-height: 100vh;
  overflow-y: auto;
}

.cardContainer {
  margin: 10px;
  padding: 1em;
  border: 1px solid $theme-gray;
  border-radius: 10px;
  background-color: $theme-white;
  box-shadow: 1px 1px 5px $theme-color-secondary;

  @include for-pure-lg-width-up {
    margin: 1em auto;
  }
}
.signInContainer {
  margin: 0 .5em;
}

.signInFormContainer {
  form {
    padding: .5em;

    label {
      text-align: left;

      @include for-pure-md-width-up {
        text-align: right;
      }
    }

    #sign-in-button-container {
      text-align: right;

      button#sign-in-button {
        width: 100%;

        @include for-pure-md-width-up {
          width: inherit;
        }
      }
    }
  }
}
