@import '../../styles/theme';
@import '../../styles/media-breakpoints';

.headerSection {
  text-align: center;

  @include for-pure-md-width-up {
    display: flex;
    align-items: center;
    text-align: left;
  }

  h1 {
    display: inline-block;
    margin-right: 1em;
  }
  .newExerciseButton {
    display: inline;
    margin-bottom: 1em;
    
    @include for-pure-md-width-up {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  .iconContainer {
    display: inline-block;
    width: 12px;
    margin-right: 5px;
  }
}

.infoRow td {
  text-align: center;
}

.tableContainer {
  overflow: auto;
}

.exercisesTable {
  width: 100%;
  min-width: 500px;

  tr {
    cursor: pointer;
  }
}

.showOnPrint {
  display: none;
}

.exercisesFilterContainer {
  margin-bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include for-pure-md-width-up {
    flex-direction: row;
  }
}
