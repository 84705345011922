@mixin card-pop {
    
    transition: .5s;
    
    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 0 25px -5px #9e9c9e;
  

    }
}