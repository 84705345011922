@import '../../styles/theme';
@import '../../styles/media-breakpoints';

.headerSection {
  text-align: center;

  @include for-pure-md-width-up {
    display: flex;
    align-items: center;
    text-align: left;
  }
  
  .clientName {
    text-decoration: underline;
  }

  h1 {
    display: inline-block;
    margin-right: 1em;
  }
}

.infoLabel {
  font-weight: 600;
  margin-right: 5px;
}
// .infoValue {
// }

.actionButton {
  background-color: $theme-white;
  background-color: transparent;
  border: 1px solid $theme-primary;
  color: $theme-primary;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.eventHistoryContainer {
  height: 500px;
  overflow-y: auto;
  box-shadow: 2px 2px 2px $theme-primary;
  border: 1px solid $theme-primary;
  border-radius: 5px;
}

.cardContainer {
  padding: .5em 1em .5em 1em;
}