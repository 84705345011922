@import '../../styles/theme.scss';
@import '../../styles/media-breakpoints.scss';

.signUp{
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContainer {
  padding: 1em;
  border: 1px solid $theme-gray;
  border-radius: 10px;
  background-color: $theme-white;
  box-shadow: 1px 1px 5px $theme-color-secondary;
  max-width: 700px;
  margin: 1em;
  position: relative;
}

.signUpForm {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonContainer {
    border-top: 1px solid $theme-gray;
    padding: 1em 0 1em 0;
    max-width: 700px;
    text-align: center;
}

.errorContainer {
    text-align: right;
}