@import '../../styles/theme';
@import '../../styles/media-breakpoints';

.headerSection {
  text-align: center;

  @include for-pure-md-width-up {
    display: flex;
    align-items: center;
    text-align: left;
  }

  h1 {
    display: inline-block;
    margin-right: 1em;
  }
  .newAppButton {
    display: inline;
    margin-bottom: 1em;
    
    @include for-pure-md-width-up {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  .iconContainer {
    display: inline-block;
    width: 12px;
    margin-right: 5px;
  }
}