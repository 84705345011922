@import "../../styles/theme";

.submitButton {
  margin-left: 1em;
  background-color: $theme-primary;
  color: $theme-font-color;
}

.cancelButton {
}

.deleteButton {
  background-color: $theme-red;
  color: $theme-font-color;
}

.nextButton, .previousButton {
  background-color: $theme-color-secondary;
  color: $theme-font-color;
}

.selectedMst {
  background-color: $theme-color-secondary;
  color: $theme-font-color;
}

.mstButtonContainer {
  display: inline-block;
}
.mstFieldset {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
}

.addButtonContainer {
  text-align: right;
}

.addMediaSourceButton {
  font-size: 75%;
  font-weight: bolder;
  background-color: $theme-green;
  color: $theme-font-color;
}

.addedMediaTable {
  width: 100%;
}

.removeMediaButton {
  background-color: transparent;
  border: none;
  color: $theme-red;
}


.validationMessage {
  color: $theme-red;
  text-align: center;
  display: block;
}

.description {
  width: 100%;
}

.descriptionLabel {
  text-align: left !important;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
}

.tdIsThumbnail {
  text-align: center;
  
  input {
    width: 16px;
    height: 16px;
  }
}

.modalBodyContainer {
  display: flex;
  justify-content: space-evenly;
  
  .modalBodyLeft {
    width: 60%;
  }

  .modalBodyRight {
    padding-left: 1em;
    border-left: 1px solid black;
  }
}

.descriptionContainer {
  padding-right: .5em;
}