@import "../../styles/theme.scss";
@import "../../styles/mixins.scss";

.container.primary {
    background-color: $theme-primary;
    color: $theme-white;
}
.container.secondary {
    background-color: $theme-color-secondary;
    color: $theme-white;
}
.container.accent {
    background-color: $theme-orange;
    color: $theme-white;
}

.container {
    @include card-pop;
    border-radius: 3px;
    height: 90px;
    
    display: flex;
    justify-content: space-between;
    
    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 5px;
        width: 100%;
    }
    
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
    }
    
    .header {
        padding: .5em;
        font-size: 14px;
    }
    .footer {
        font-size: 14px;
    }
    
    .valueContainer {
        font-size: 30px;
        font-weight: 600;
        min-height: 33px;
        
        display: flex;
        align-items: center;
        
        &.isText {
            font-size: 20px;
        }
    }
}