@import '../../styles/theme.scss';
@import '../../styles/media-breakpoints.scss';

.home {
  max-height: 100vh;
  overflow-y: auto;
}

.cardContainer {
  margin: 10px;
  padding: 1em;
  border: 1px solid $theme-gray;
  border-radius: 10px;
  background-color: $theme-white;
  box-shadow: 1px 1px 5px $theme-color-secondary;
  position: relative;

  @include for-pure-lg-width-up {
    margin: 1em auto;
  }
}

.welcomeMessage {
  text-align: center;
}

.summaryMessage {
  text-align: center;
}

.summaryContainer {
  position: relative;
  min-height: 50vh;
}

.summaryCardContainer {
  margin: 1em;
}

.preferencesIconContainer {
  display: inline-block;
  width: 14px;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.submitButton {
  margin-left: 1em;
  background-color: $theme-primary;
  color: $theme-font-color;
  float: right;
}

.formContainer {
  padding: 5px 0 5px 0;
}