$color-primary-0: #7A68EE;	// Main Primary color *!*/
$color-primary-1: #C6BEFB;
$color-primary-2: #9C8EF5;
$color-primary-3: #5C47E4;
$color-primary-4: #3D24D5;

/*$color-secondary-1-0: #AB5EED;	// Main Secondary color (1) *!*/
$color-secondary-1-1: #DCB9FB;
$color-secondary-1-2: #C186F4;
$color-secondary-1-3: #953BE3;
$color-secondary-1-4: #7C18D2;

/*$color-secondary-2-0: #FFF85A;	// Main Secondary color (2) *!*/
$color-secondary-2-1: #FFFCB7;
/*$color-secondary-2-2: #FFFA84;*/
/*$color-secondary-2-3: #FFF635;*/
/*$color-secondary-2-4: #FFF40C;*/

/*$color-complement-0: #FFDD5A;	// Main Complement color *!*/
/*$color-complement-1: #FFF0B7;*/
/*$color-complement-2: #FFE584;*/
/*$color-complement-3: #FFD535;*/
$color-complement-4: #FFCC0C;

/*$theme-white: white;*/
/*$theme-gray: #e3e8ee;*/
/*$theme-gray-light: #f1f1f1;*/
/*$theme-black: #120A2A;*/

/*$theme-font-color: $theme-white;*/
/*$theme-color-main: $color-primary-3;*/
/*$theme-color-secondary: $color-primary-0;*/
/*$theme-color-dark: $color-primary-4;*/

/*$theme-color-accent: $color-complement-4;*/


/*$main-background: $theme-gray;*/

$theme-black: #120A2A;
$theme-white: #FFFFFF;
$theme-gray: #e3e8ee;
$theme-gray-light: #f1f1f1;
$theme-blue: #99CCFF;
$theme-green: #31b146;
$theme-red: #dc3545;
$theme-orange: #ff851b;

$theme-primary: #5C47E4;
$theme-color-secondary: $color-primary-0;
$theme-accent: $color-complement-4;

$theme-background: $theme-gray;
$theme-font-color: $theme-white;
