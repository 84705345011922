.formContainer {
  
}

.label {
  font-weight: 700;
  margin-right: .5em;
}

.spacer {
  padding-top: 1em;
}

.fullWidth {
  width: 100%;
  display: block;
}

.textArea {
  width: 90%;
  display: block;
}