@import "../../styles/theme.scss";
@import "../../styles/mixins.scss";


.datalist {
    @include card-pop;
    padding: 0 1em 1em 1em;

    .logIcon {
        color: gray;
        display: inline-block;
        width: 10px;
    }
    .date {
        margin-left: 1em;
        font-size: 14px;
        color: rgba($theme-black, .7);
    }

    dt {
        margin-bottom: 3px;
    }
    
    dd {
        margin-bottom: .5em;
    }
}

.noDetails {
    text-align: center;
    font-size: 25px;
    color: rgba($theme-black, .60);
    font-style: italic;
}