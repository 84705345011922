
.datalist {
    padding: 0 1em 1em 1em;
    height: 300px;
    overflow-y: scroll;

    .logIcon {
        color: gray;
        margin-right: 1em;
    }
    .userLogIcon {
        color: gray;
        margin-left: 1em;
    }
    .date {
        // margin-left: 1em;
        // font-weight: bolder;
        font-size: 12px;
        font-style: italic;
    }

    dt {
        margin-bottom: 3px;
        font-weight: 600;
    }
    
    dd {
        margin-left: 1em;
        margin-bottom: .5em;
    }
    
    dt.userMessage, dd.userMessage {
        text-align: right;
    }
}

.messageBoxContainer {
    width: 100vw;
    padding: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    textarea, button {
        width: 100%;
    }
    
    .messageButtonContainer {
        padding-top: 1em;
        width: 100%;
        
        button {
            width: 100%;
        }
    }
}