@import "../../../../styles/theme.scss";

.cardContainer {
    border: 2px solid darkgrey;
    background-color: $theme-gray;
    border-radius: 8px;
    margin: 5px;
    cursor: pointer;

    &.selected {
        background-color: $theme-blue;
        border-color: darken($theme-blue, 20%);

        .iconContainer svg {
            color: green;
            font-weight: 600;
        }
    }

    &:hover {
        box-shadow: 3px 3px 5px;
    }

    header {
        margin-top: 5px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px 0 5px;

        .iconContainer {
            display: inline-block;
            width: 14px;
        }

    }


    p {
        text-align: center;
    }
}