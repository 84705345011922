@import "../../../styles/media-breakpoints.scss";

.checkboxContainer {
  padding: 5px 0 5px 0;

  @include for-pure-md-width-up {
    padding: 0;
  }
}

.checkboxLabel {
  display: inline-flex;
  // align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  
  @include for-pure-md-width-up {
    justify-content: center;
  }
  
  input {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  
  .labelHeaderText {
    margin-left: 5px;
    font-weight: 600;
  }
}
