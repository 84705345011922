@import "../../styles/theme.scss";
@import "../../styles/media-breakpoints.scss";

.navSectionContainer {
    background-color: $theme-black;
    color: $theme-white;

    @include for-pure-sm-width-up {
      height: 100%;
    }

    @include for-pure-md-width-up {
      padding-top: 1.5em;
    }

}

.navSection {
    padding-bottom: .5em;
}

.navList {
    list-style: none;
    padding: 0;
}

.navList li {
    padding: .5em 0 .5em 1em;
    cursor: pointer;
}

.navList li:hover {
    color: $color-primary-2;
}

.navList li a {
    color: $theme-black;
    text-decoration: none;
    font-weight: 700;
}

.navList li a i {
    padding-right: .5em;
}

.appSection {
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid $theme-white;
    border-top: 1px solid $theme-white;
    margin: 0 10px 0 10px;
    padding: 1em 0 1em 0;
}

.iconContainer {
    display: inline-block;
    width: 14px;
    margin-right: .5em;
}

.selected {
    background-color: rgba($theme-primary, 1);
}