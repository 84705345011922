@import "../../styles/theme";
@import "../../styles/media-breakpoints";

.toggleButtonContainer {
  text-align: right;
}


.toggleButton {
  position: relative;
  background-color: transparent;
  color: $theme-font-color;
  width: 50px;
  border: none;
  outline: none;
  box-shadow: none;
  padding: .75em;
}
