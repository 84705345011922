@import "../../../../styles//theme.scss";

li.headerNavLi {
    position: relative;

    &:hover {
        color: $theme-white;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .headerIcon {
            margin-right: 1em;
            display: inline-block;
            width: 14px;
        }
    }

    .childLinksUl {
        list-style: none;
        padding-left: 0;

        &.hide {
            display: none;
        }

        .childLinkLi {

        }
    }
}