@import "../../styles/theme";

.submitButton {
  margin-left: 1em;
  background-color: $theme-primary;
  color: $theme-font-color;
  float: right;
}

.cancelButton {
  float: left;
}

.validationMessage {
  color: red;
  text-align: center;
  display: block;
}

.description {
  width: 100%;
}

.descriptionLabel {
  text-align: left !important;
}