@import '../../styles/theme.scss';
@import '../../styles/media-breakpoints.scss';

.inventory {
  max-height: 100vh;
  overflow-y: auto;
}

.cardContainer {
  margin: 10px;
  padding: 1em;
  border: 1px solid $theme-gray;
  border-radius: 10px;
  background-color: $theme-white;
  box-shadow: 1px 1px 5px $theme-color-secondary;

}
